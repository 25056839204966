import React from 'react'

import logo from './images/logo.png';

function Loading() {
  return (
    <div style={{
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, .8)',
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        left: '0',
        position: 'absolute',
        top: '0',
        width: '100vw',
        zIndex: '999'
    }}>
        <img src={logo} alt="Logo" style={{
            width: '80px',
        }} />
        <p style={{
            color: 'rgba(255, 255, 255, .5)',
            fontFamily: 'Roboto',
            fontWeight: '300', 
        }}>Carregando página...</p>
    </div>
  )
}

export default Loading