import React, { useState } from 'react'

import logo from './images/logo.png';
import icWhats from './icons/whatsapp.svg';
import icFace from './icons/facebook.svg';
import icInsta from './icons/instagram.svg';
import icGmail from './icons/gmail.svg';
import icLkdin from './icons/linkedin.svg';
import icOulk from './icons/outlook.svg';

// Icones Pagamentos
import mastercard from './icons/pagamentos/mastercard.svg';
import amex from './icons/pagamentos/amex.svg';
import elo from './icons/pagamentos/elo.svg';
import hipercard from './icons/pagamentos/hipercard.svg';
import visa from './icons/pagamentos/visa.svg';
import pix from './icons/pagamentos/pix.svg';


export default function Contato() {

    const [gmailMessage, setGmailMessage] = useState('')
    const [mailDev, setMailDev] = useState('')

    const handleCopyGmail = (mail) => {
        if (mail === 'jc') {
            navigator.clipboard.writeText("jcarlos.gomes90@outlook.com")
            setGmailMessage('Gmail copiado!')
            resetMessage()
        } else {
            navigator.clipboard.writeText("fagner.costanunes@hotmail.com")
            setMailDev('Email copiado!')
            resetMessage()
        }
    }

    const resetMessage = () => {
        setTimeout(() => {
            setGmailMessage('')
            setMailDev('')
        }, 2000)
    }

    return (
        <footer className="contato" id="contato">

            <div className="container-contato">
                <div className="part1" animation1="off">
                    <h1 className='titulo-section'>Contate-nos</h1>
                    <p className='text'>Entre em contato conosco e faça já o seu orçamento sem compromisso!</p>

                    <div className="redes-sociais">
                        <a href="https://www.facebook.com/jcmplanejados1" target="_blank">
                            <img src={icFace} alt="icon-face" />
                        </a>
                        <a href="https://www.instagram.com/jc_mplanejados/" target="_blank">
                            <img src={icInsta} alt="icon-insta" />
                        </a>
                        <a onClick={() => handleCopyGmail('jc')} href='mailto:jcarlos.gomes90@outlook.com'>
                            <img src={icGmail} alt="icon-gmail" />
                            <p>{gmailMessage}</p>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=551199338-1771&text=Ol%C3%A1%20estou%20fazendo%20contato%20atrav%C3%A9s%20do%20seu%20site%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os" target="_blank">
                            <img src={icWhats} alt="icon-whats" />
                        </a>
                    </div>

                    <div className='pagamentos'>
                        <p>Aceitamos cartões de crédito e débito!</p>
                        <div className="cartoes">
                            <img src={visa} alt="visa" />
                            <img src={mastercard} alt="mastercard" />
                            <img src={elo} alt="elo" />
                            <img src={hipercard} alt="hipercard" />
                            <img src={amex} alt="amex" />
                            <img src={pix} alt="pix" />
                        </div>
                    </div>
                </div>

                <picture animation1="off">
                    <img src={logo} alt="logo-jc" />
                    <p>Aqui a sua montagem é levada a sério!</p>
                    <h4>Todos os direitos reservados.</h4>
                </picture>

                <div className="part2" animation1="off">
                    <div className="desenvolvedor">
                        <p>Design e desenvolvimento:</p>
                        <p>Fagner da Costa Nunes</p>
                        <div className="redes">
                            <a href="https://www.linkedin.com/in/fagnernunes/" target='_blank'>
                                <img src={icLkdin} alt="icon-linkedin" />
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=551194716-5462&text=Ol%C3%A1%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os" target='_blank'>
                                <img src={icWhats} alt="icon-oputlook" />
                            </a>
                            <p>{mailDev}</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}