import React, { useState, useEffect } from 'react';

import logo from './images/logo.png';

import ButtonScrollTop from './sec-components/ButtonScrollTop';

export default function Header () {

    const [seeMenu, setSeeMenu] = useState(false);
    
    const scrollToSection = (id, activeMenu = true) => {

        const element = document.querySelector(id);
        const offsetTop = element.offsetTop;

        if(element) {
            window.scrollTo({
                top: offsetTop - 80,
                behavior: 'smooth',
            });
            activeMenu && setSeeMenu(!seeMenu);
        }
    }


    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {

        const handleScroll = () => {
          const position = window.pageYOffset;
          setScrollPosition(position);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
        
            <header>
                <div className="container">
                    <div className="logo">
                        <img src={logo} alt="logo-jc" />
                    </div>

                    <button className="btn-menu-hamburguer" onClick={() => setSeeMenu(!seeMenu)}>
                        {
                            seeMenu ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 x">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="#000" className="w-6 h-6 menu">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            )
                        }
                    </button>
                    
                    <nav className={`${seeMenu ? 'open' : ''}`}>
                        <ul>
                            <li><a onClick={() => scrollToSection("#home")}>Home</a></li>
                            <li><a onClick={() => scrollToSection("#quem-somos")}>Quem somos</a></li>
                            <li><a onClick={() => scrollToSection("#nossos-servicos")}>Nossos serviços</a></li>
                            <li><a onClick={() => scrollToSection("#avaliacoes")}>Avaliações</a></li>
                            <li><a onClick={() => scrollToSection("#localidades")}>Localidades</a></li>
                            <li><a onClick={() => scrollToSection("#contato")}>Contate-nos</a></li>
                        </ul>
                        <div className="redes-sociais">
                            <a href="https://api.whatsapp.com/send?phone=551199338-1771&text=Ol%C3%A1%20estou%20fazendo%20contato%20atrav%C3%A9s%20do%20seu%20site%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os" target="_blank">
                                <i className="fa fa-whatsapp"></i>
                            </a>
                            <a href="https://www.facebook.com/jcmplanejados1" target="_blank">
                                <i className="fa fa-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/jc_mplanejados/" target="_blank">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </nav>

                </div>
            </header>

            {
                scrollPosition >= 400 && (
                    <ButtonScrollTop 
                        func={scrollToSection}
                    />
                )
            }

        </>

    )
}
