import React from 'react'

import imgLocalidades from './images/img-servicos3.png';
import quebraCabeca from './images/quebra-cabeça.png';

export default function Localidades() {

    return (
        <section className='localidades' id='localidades'>
            <div className="container-localidades">
                <h1 className='titulo-section'>Localidades</h1>

                <div className="conteudo">
                    <picture animation2="off">
                        <img src={imgLocalidades} alt="localidades" />
                    </picture>
                    
                    <div className="lista-localidades" animation1="off">
                        <h3>Atendemos <span>São Paulo</span> e região!</h3>

                        <div className='container-zonas'>
                            <p className="zona-n">Norte</p>
                            <p className="zona-s">Sul</p>
                            <p className="zona-l">Leste</p>
                            <p className="zona-o">Oeste</p>
                            <img src={quebraCabeca} alt="quebra-cabeça" />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}