import React from 'react'

export default function Avaliacoes() {

    const avaliacoes = [
        {
            info: 'Andreia - Guarda-roupas Zona norte, São Paulo - SP.',
            mensagem: 'Excelentes profissionais e atenciosos, desde do primeiro momento até a realização do trabalho.. super indico, estão de parabéns !!!'
        },
        {
            info: 'Kátia - Armário de cozinha Zona leste, Arujá - SP.',
            mensagem: 'Boa tarde, sou a Kátia e estou aqui para agradecer pelo seu trabalho. Hoje em dia é difícil encontrar um profissional que nos transmita segurança para trabalhar em nossa casa. Agradeço a você e sua equipe por arrumarem tudo que era necessário na minha casa. Vou indicar vocês, muito obrigada mesmo! Confio e indico, JC!'
        },
        {
            info: 'Vanessa - Armário de cozinha Zona leste, Ferraz de Vasconcelos - SP.',
            mensagem: 'Tô passando pra agradecer pelo serviço prestado, vc é muito educado e foi bem rápido...Gostei bastante da qualidade da montagem viu. Obrigada.'
        },
    ];

  return (

    <section className="avaliacoes" id="avaliacoes">
        
        <div className="container-avaliacoes">
            <h1 className="titulo-section" animation2="off">Avaliações</h1>

            <div className="cards">
                <div className="line"></div>

                {
                    avaliacoes?.map((avl, index) => {
                        return (
                            <div key={index} className="card" animation1="off">
                                <p className="infos">
                                    {avl.info}
                                </p>

                                <p className="mensagem">
                                    <span>“ </span>
                                        {avl.mensagem}
                                    <span> “</span>
                                </p>
                            </div>
                        );
                    })
                }

            </div>
        </div>

    </section>

  )
}