import React from "react";

import imgHome from "./images/home.png";

const handleBtnServicos = () => {
    const element = document.querySelector("#nossos-servicos");
    const offsetTop = element.offsetTop;

    window.scrollTo({
        top: offsetTop - 80,
        behavior: "smooth",
    });
};

export default function Home() {
    return (
        <section className="home" id="home">
            <div className="container-conteudo">
                <div className="conteudo">
                    <div className="text">
                        <h1>Bem vindo a JC Móveis</h1>
                        <p>Aqui a sua montagem é levada a sério!</p>
                        <div>
                            <button
                                type="button"
                                className="btn-home"
                                onClick={() => handleBtnServicos()}
                            >
                                Conheça nossos serviços
                            </button>
                            <a className="btn-wpp-home"
                                target="_blank"
                                href="https://api.whatsapp.com/send?phone=551199338-1771&text=Ol%C3%A1%20estou%20fazendo%20contato%20atrav%C3%A9s%20do%20seu%20site%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os"
                            >
                                <button type="button" className="btn-home">
                                    Faça um orçamento rápido
                                </button>
                            </a>
                        </div>
                    </div>

                    <picture>
                        <img src={imgHome} alt="img-home" />
                    </picture>
                </div>

                <div className="btns-home">
                    <button type="button" onClick={() => handleBtnServicos()}>
                        Conheça nossos serviços
                    </button>
                    <a
                        target="_blank"
                        href="https://api.whatsapp.com/send?phone=551199338-1771&text=Ol%C3%A1%20estou%20fazendo%20contato%20atrav%C3%A9s%20do%20seu%20site%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os"
                    >
                        <button type="button">Clique aqui e faça seu orçamento</button>
                    </a>
                </div>
            </div>
        </section>
    );
}
