import React, { useEffect, useState } from 'react';

import './styles/styles-components.scss';

import Header from './Header';
import Home from './Home';
import QuemSomos from './QuemSomos';
import NossosServicos from './NossosServicos';
import Avaliacoes from './Avaliacoes';
import Localidades from './Localidades';
import Contato from './Contato';
import Loading from './Loading';
import ButtonWhats from './sec-components/ButtonWhats';

export default function Main () {
    
    useEffect(() => {

        const width = window.screen.width;
        
        const animateBottom = () => {
            const $el = document.querySelectorAll('[animation1="off"]');
            const height = window.screen.height;

            let topPage;

            topPage = window.pageYOffset + height;

            if(width >= 1024) {
                topPage = window.pageYOffset + height - 250;
            }

            $el.forEach(e => {
                topPage > e.offsetTop && e.setAttribute('animation1', 'bottom');
            })
        }

        const animateLeft = () => {
            const $el = document.querySelectorAll('[animation2="off"]');
            const height = window.screen.height;

            let topPage;
            let animateFrom;

            topPage = window.pageYOffset + height;
            animateFrom = 'bottom';

            if(width >= 1024) {
                topPage = window.pageYOffset + height - 250;
                animateFrom = 'left';
            }
            
            $el.forEach(e => {
                topPage > e.offsetTop && e.setAttribute('animation2', `${animateFrom}`);
            })
        }

        window.addEventListener('scroll', () => {
            animateBottom();
            animateLeft();
        })
    })

    const [mostrarConteudo, setMostrarConteudo] = useState(false);

    window.onload = function () {
        setMostrarConteudo(true)
    }

    return mostrarConteudo === false ? <Loading /> 
    : (
        <main>
            <Header />
            <Home />
            <QuemSomos />
            <NossosServicos />
            <Avaliacoes />
            <Localidades />
            <Contato />
            <ButtonWhats />
        </main>
    )
        
    
}
