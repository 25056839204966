import React from 'react'

import icWhats from '../icons/icone-wpp.png';

function ButtonWhats() {
  return (

    
    <a href="https://api.whatsapp.com/send?phone=551199338-1771&text=Ol%C3%A1%20estou%20fazendo%20contato%20atrav%C3%A9s%20do%20seu%20site%20gostaria%20de%20maiores%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os" target="_blank" style={
        {   
            bottom: 120,
            position: 'fixed',
            right: '6.5%',
            width: '16vw',
            maxWidth: '60px',
            zIndex: 9,
        }
    }>
        <img src={icWhats} alt="icon-whats" style={
            {
                borderRadius: '100%',
            }
        }/>
    </a>
  )
}

export default ButtonWhats