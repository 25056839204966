import React, { useState } from "react";

import imgTeste3 from "./images/eu-concerto.png";
import imgTeste2 from "./images/logo.png";
import imgTeste from "./images/quem-somos.jpg";
import clique from "./icons/clique.gif";

// Guarda-Roupas
import groupas1 from "./images/guarda-roupas/groupas-1.jpg";
import groupas2 from "./images/guarda-roupas/groupas-2.jpg";
import groupas3 from "./images/guarda-roupas/groupas-3.jpg";
import groupas4 from "./images/guarda-roupas/groupas-4.jpg";
import groupas5 from "./images/guarda-roupas/groupas-5.jpg";
import groupas6 from "./images/guarda-roupas/groupas-6.jpg";

// Armários
import armario1 from "./images/armarios/armario-1.jpg";
import armario2 from "./images/armarios/armario-2.jpg";
import armario3 from "./images/armarios/armario-3.jpg";
import armario4 from "./images/armarios/armario-4.jpg";

// Paineis e Raques
import pnraque1 from "./images/paineis-e-raques/pnraque-1.jpg";
import pnraque2 from "./images/paineis-e-raques/pnraque-2.jpg";
import pnraque3 from "./images/paineis-e-raques/pnraque-3.jpg";
import pnraque4 from "./images/paineis-e-raques/pnraque-4.jpg";

// Mesas
import mesa1 from "./images/mesas/mesa-1.jpg";
import mesa2 from "./images/mesas/mesa-2.jpg";
import mesa3 from "./images/mesas/mesa-3.jpg";
import mesa4 from "./images/mesas/mesa-4.jpg";
import mesa5 from "./images/mesas/mesa-5.jpg";

export default function NossosServicos() {
    const [indexG, setIndexG] = useState(0);
    const [indexA, setIndexA] = useState(0);
    const [indexR, setIndexR] = useState(0);
    const [indexM, setIndexM] = useState(0);

    const servicos = [
        {
            title: "Guarda-roupas",
            index: indexG,
            setIndex: setIndexG,
            images: [
                {
                    id: 1,
                    tagImg: (
                        <img
                            className="see"
                            src={groupas1}
                            alt="img-guarda-roupas-1"
                        />
                    ),
                },
                {
                    id: 2,
                    tagImg: (
                        <img
                            className="see"
                            src={groupas2}
                            alt="img-guarda-roupas-2"
                        />
                    ),
                },
                {
                    id: 3,
                    tagImg: (
                        <img
                            className="see"
                            src={groupas3}
                            alt="img-guarda-roupas-3"
                        />
                    ),
                },
                {
                    id: 4,
                    tagImg: (
                        <img
                            className="see"
                            src={groupas4}
                            alt="img-guarda-roupas-4"
                        />
                    ),
                },
                {
                    id: 5,
                    tagImg: (
                        <img
                            className="see"
                            src={groupas5}
                            alt="img-guarda-roupas-5"
                        />
                    ),
                },
                {
                    id: 6,
                    tagImg: (
                        <img
                            className="see"
                            src={groupas6}
                            alt="img-guarda-roupas-6"
                        />
                    ),
                },
            ],
        },
        {
            title: "Armários",
            index: indexA,
            setIndex: setIndexA,
            images: [
                {
                    id: 1,
                    tagImg: (
                        <img
                            className="see"
                            src={armario1}
                            alt="img-armario-1"
                        />
                    ),
                },
                {
                    id: 2,
                    tagImg: (
                        <img
                            className="see"
                            src={armario2}
                            alt="img-armario-2"
                        />
                    ),
                },
                {
                    id: 3,
                    tagImg: (
                        <img
                            className="see"
                            src={armario3}
                            alt="img-armario-3"
                        />
                    ),
                },
                {
                    id: 4,
                    tagImg: (
                        <img
                            className="see"
                            src={armario4}
                            alt="img-armario-4"
                        />
                    ),
                },
            ],
        },
        {
            title: "Painéis e Racks",
            index: indexR,
            setIndex: setIndexR,
            images: [
                {
                    id: 1,
                    tagImg: (
                        <img
                            className="see"
                            src={pnraque1}
                            alt="img-painel-e-rack-1"
                        />
                    ),
                },
                {
                    id: 2,
                    tagImg: (
                        <img
                            className="see"
                            src={pnraque2}
                            alt="img-painel-e-rack-2"
                        />
                    ),
                },
                {
                    id: 3,
                    tagImg: (
                        <img
                            className="see"
                            src={pnraque3}
                            alt="img-painel-e-rack-3"
                        />
                    ),
                },
                {
                    id: 4,
                    tagImg: (
                        <img
                            className="see"
                            src={pnraque4}
                            alt="img-painel-e-rack-4"
                        />
                    ),
                },
            ],
        },
        {
            title: "Mesas",
            index: indexM,
            setIndex: setIndexM,
            images: [
                {
                    id: 1,
                    tagImg: (
                        <img className="see" src={mesa1} alt="img-mesa-1" />
                    ),
                },
                {
                    id: 2,
                    tagImg: (
                        <img className="see" src={mesa2} alt="img-mesa-2" />
                    ),
                },
                {
                    id: 3,
                    tagImg: (
                        <img className="see" src={mesa3} alt="img-mesa-3" />
                    ),
                },
                {
                    id: 4,
                    tagImg: (
                        <img className="see" src={mesa4} alt="img-mesa-4" />
                    ),
                },
                {
                    id: 5,
                    tagImg: (
                        <img className="see" src={mesa5} alt="img-mesa-5" />
                    ),
                },
            ],
        },
    ];

    return (
        <section className="nossos-servicos" id="nossos-servicos">
            <div className="container-nossos-servicos">
                <div className="parte-texto" animation2="off">
                    <h1 className="titulo-section">Nossos Serviços</h1>
                    <div>
                        <p>Confira algumas de nossas montagens!</p>
                        <p className="info">
                            Clique na imagem para visualizar outros móveis.
                        </p>
                    </div>
                </div>

                <div className="container-servicos">
                    {servicos?.map((res, index) => {
                        return (
                            <div
                                className="servicos"
                                key={index}
                                animation1="off"
                            >
                                <p className="titulo-servicos">{res.title}</p>

                                <picture
                                    animation="bottom"
                                    onClick={() => {
                                        res.index === res.images.length - 1
                                            ? res.setIndex(0)
                                            : res.setIndex(res.index + 1);
                                    }}
                                >
                                    {res.images[res.index].tagImg}

                                    <img
                                        className="clique"
                                        src={clique}
                                        alt="clique-aqui"
                                    />
                                </picture>
                            </div>
                        );
                    })}

                    <div className="demais-servicos" animation2="off">
                        <h2>
                            No Jc Móveis você também encontra serviços como:
                        </h2>
                        <ul>
                            <li>
                                Montagem de móveis convencionais e planejados;
                            </li>
                            <li>Reparo de móveis;</li>
                            <li>Instalação de portas camarão;</li>
                            <li>Marcenaria;</li>
                            <li>Recortes;</li>
                            <li>Reformas.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
