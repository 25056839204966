import React from "react";

import imgQuemsomos from "./images/sobre-nos-2.png";

export default function QuemSomos() {
    return (
        <>
            <section className="quem-somos" id="quem-somos">
                <div className="container-quem-somos">
                    <div className="parte-texto" animation2="off">
                        <h1 className="titulo-section">Quem Somos</h1>
                        <div>
                            <p>
                                Somos uma empresa especializada em prestar
                                serviços de montagem e desmontagem de móveis
                                convencionais e planejados com eficácia e
                                qualidade.
                                <strong> Nossa missão</strong> é garantir a
                                satisfação dos clientes, prestando serviços
                                precisos e detalhados.
                                <strong> Temos como visão</strong> ser líderes no
                                mercado e oferecer serviços rápidos e
                                confiáveis, com uma equipe capacitada e
                                comprometida.
                            </p>
                            <p>
                                Prezamos por um preço justo, sem abrir mão da
                                qualidade e transparência em nossos negócios.
                                Para montagem ou desmontagem de móveis, entre em
                                contato conosco <strong>JC Móveis!</strong>
                            </p>
                        </div>
                    </div>

                    <picture animation2="off">
                        <img src={imgQuemsomos} alt="img-quem-somos" />
                    </picture>
                </div>
            </section>
        </>
    );
}
